import React, { useEffect } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import Theme from './components/Theme';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './styles.scss';
import translations from './resources.json' assert { type: 'json' };
import { supportedLanguages, Translations } from './components/LanguageSelector';
import Contact from './Contact';
import Home from './Home';
import Menu from './components/Menu';
import { Container } from '@mui/material';
import Reservation from './Reservation';
import { setSession } from './utils/SessionUtils';

const typedTranslations = translations as Translations;
const routeDefinitions = [
  { path: '', component: Home },
  { path: 'reservation', component: Reservation },
  { path: 'contact', component: Contact },
];

const renderVersion = () => {
  const version = sessionStorage.getItem('version');
  if (!version) {
    const buildId = process.env.BUILD_ID ?? 'not set';
    console.log(`Version ${buildId}`);
    sessionStorage.setItem('version', buildId);
  }
}

const createLanguageRoutes = (lang: string) => {
  const languageDetail = typedTranslations[lang as keyof Translations];
  return routeDefinitions.map(({ path, component: Component }) => (
    <Route key={`${lang}-${path || 'home'}`} path={`${lang}/${path}`} element={<Component {...languageDetail} />} />
  ));
};

const Root: React.FC = () => {
  renderVersion();
  const useLangCode = (): string => {
    const location = useLocation();
    const match = location.pathname.match(/^\/([a-z]{2})(?:\/|$)/i);
    return match ? match[1] : 'en';
  };
  
  useEffect(() => {
    setSession('lang', langCode);
  }, []);
  
  const langCode = useLangCode();
  const t = typedTranslations[langCode as keyof Translations];
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Menu translations={typedTranslations} languageDetail={t} selectedLanguage={langCode} />
      <Container>
        <Routes>
          <Route path="/" element={<Home {...t} />} />
          {supportedLanguages.map((lang) => createLanguageRoutes(lang))}
        </Routes>
      </Container>
    </ThemeProvider>
  );
};

const root = createRoot(document.getElementById('app-root')!);

root.render(
  <React.StrictMode>
    <Router>
      <Root />
    </Router>
  </React.StrictMode>
);