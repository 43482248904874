import React, { useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { setSession } from '../utils/SessionUtils';

export interface Translations {
  en: LanguageDetail,
  it: LanguageDetail,
  cs: LanguageDetail,
  de: LanguageDetail,
  sq: LanguageDetail,
  sr: LanguageDetail,
  mk: LanguageDetail,
  fr: LanguageDetail,
  pl: LanguageDetail,
}

export const supportedLanguages = ['en','it','de','fr','cs','pl','sq','mk','sr'];

interface LanguageSelectorProps extends Translations {
    onChange: (language: string) => void;
    selectedDetail: LanguageDetail;
}

function LanguageSelector(props: Readonly<LanguageSelectorProps>) {
  const [language, setLanguage] = useState(props.selectedDetail.code);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSession('lang', event.target.value);
    setLanguage(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <>
    <span style={{ marginLeft: 'auto', marginRight: '5px'}}>{props.selectedDetail.language} </span>
    <Select
      value={language}
      onChange={handleChange}
      variant="outlined"
    >
      {supportedLanguages.map((lang) => {
        const languageDetail = props[lang as keyof Translations];
        return (<MenuItem value={languageDetail.code} key={lang}>
          <span className={`fi fi-${languageDetail.iconCode}`}></span>
        </MenuItem>);
      })}
    </Select>
    </>
  );
}

export default LanguageSelector;
