import React from 'react';
import Parser from 'html-react-parser';
import albania from './img/albania.webp';
import MetaTags from './components/MetaTags';

function Contact(props: LanguageDetail) {
  const user = 'stay';
  const domain = 'lovebania.com';
  const mail = `${user}@${domain}`
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.contact}
        url={window.location.href}
        image={albania} />
      <h1>{ Parser(props.contact) }</h1>
      <p>
        {`${props.contactInfo} `}
        <a href={`mailto:${mail}`}>
          {user}@{domain}
        </a>
      </p>
      <p>
        <a href="https://www.google.com/maps/@41.2739861,19.5224289,17z" target='_blank'>
          <img width='100%' src="https://maps.googleapis.com/maps/api/staticmap?zoom=16&size=800x400&markers=color%3Ared%7Clabel%3AL%7C41.275716783471545%2C19.51878971936384&key=AIzaSyAxlY_KL3RzRoekgCGofZ5zI3lWVz1m1iY" alt='Lovebania appartment' />
        </a>
      </p>
    </>
  );
}

export default Contact;
