import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Autocomplete,
  Alert,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Countries } from "./Countries";
import { IFormState } from "./IFormState";
import { checkForm, getEmptyFormState } from "./FormHelpers";
import ReservationFormReview from "./ReservationFormReview";
import { isDev } from "../../utils/DevUtils";
import DateRangeSelector from "../DateRangeSelector";

const mainSeasonPrice = isDev() ? 2 : 80;
const sideSeasonPrice = isDev() ? 1 : 60;
const tidyPrice = isDev() ? 1 : 40;
const mainSeasonMonths = [6, 7, 8, 9];
const countryList = Countries.map((c) => c.name);
const numbersOfGuests = ["1", "2", "3", "4", "5"];

function ReservationForm(props: Readonly<LanguageDetail>) {
  const [formState, setFormState] = useState<IFormState>(
    getEmptyFormState(true)
  );
  const [isPreview, setIsPreview] = useState(false);
  const [paymentType, setPaymentType] = useState('sepa');
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
  const r = props;
  const titles = [r.mr, r.mrs, r.ms];

  function clearForm() {
    setFormState(getEmptyFormState(false));
    setFeedbackMessage(null);
  }

  const handleInputChange = (field: keyof IFormState, value: any) => {
    setFormState((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!checkForm(formState)) {
      setFeedbackMessage(r.fixForm);
      return;
    }

    const isMainSeason = mainSeasonMonths.some(m => m === formState.dateFrom?.month() || m === formState.dateTo?.month());
    const dailyPrice = isMainSeason ? mainSeasonPrice : sideSeasonPrice;
    const amount = dailyPrice * formState.dateTo!.diff(formState.dateFrom, 'day') + tidyPrice;
    const newState = {...formState, amount: amount};
    setFormState(newState);
    sessionStorage.setItem("formState", JSON.stringify(newState));
    setIsPreview(true);
  };

  return (
    <>
      <div style={{ display: isPreview ? 'none' : 'block'}}>
        <FormControl>
          <FormLabel id="radio-buttons-group">{props.paymentType}</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={paymentType}
            onChange={(_, val) => setPaymentType(val)}
          >
            <FormControlLabel value="sepa" control={<Radio />} label={r.sepa} />
            { isDev() && <><FormControlLabel value="btc" control={<Radio />} label={r.bitcoin} />
            <FormControlLabel value="booking" control={<Radio />} label={r.booking} /> </>}
          </RadioGroup>
        </FormControl>
      </div>
      <p style={{ display: paymentType === 'booking' ? 'block' : 'none'}}>
        {r.goTo} <a href='https://www.booking.com'>booking.com</a>
      </p>
      <div style={{ display: paymentType !== 'booking' ? 'block' : 'none'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {!isPreview && <Box sx={{ p: 3 }}>
            {feedbackMessage && (
              <Alert severity="info">{feedbackMessage}</Alert>
            )}
            <Box
              display="grid"
              gridTemplateColumns={{
                xs: "1fr",
                sm: "1fr 1fr",
              }}
              gap={2}
            >
              <TextField
                select
                label={r.addressing}
                fullWidth
                value={formState.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
              >
                {titles.map((title) => (
                  <MenuItem key={title} value={title}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                label={r.firstName}
                fullWidth
                value={formState.firstName}
                onChange={(e) =>
                  handleInputChange("firstName", e.target.value)
                }
              />
              <TextField
                required
                label={r.lastName}
                fullWidth
                value={formState.lastName}
                onChange={(e) =>
                  handleInputChange("lastName", e.target.value)
                }
              />
              <TextField
                required
                type="email"
                label={r.mail}
                fullWidth
                value={formState.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
              <TextField
                required
                type="tel"
                label={r.phone}
                fullWidth
                value={formState.phone}
                onChange={(e) => handleInputChange("phone", e.target.value)}
              />
              <TextField
                required
                label={r.address}
                fullWidth
                value={formState.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
              />
              <TextField
                required
                label={r.city}
                fullWidth
                value={formState.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
              />
              <Autocomplete
                options={countryList}
                value={formState.country}
                onChange={(e, value) => handleInputChange("country", value)}
                renderInput={(params) => (
                  <TextField {...params} label={r.country} />
                )}
              />
              <DateRangeSelector resource={props} onDateChanged={(field, date) => handleInputChange(field, date)} />
              <TextField
                select
                label={r.numberOfGuests}
                fullWidth
                value={formState.numberOfGuests}
                onChange={(e) =>
                  handleInputChange("numberOfGuests", e.target.value)
                }
              >
                {numbersOfGuests.map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                  label={r.note}
                  fullWidth
                  value={formState.note}
                  onChange={(e) => handleInputChange("note", e.target.value)}
                  multiline
                  rows={3}
                  sx={{ mb: 2 }}
                />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isPreview}
                sx={{ mr: 2 }}
              >
                {r.previewOrder}
              </Button>
              <Button
                variant="outlined"
                onClick={clearForm}
                disabled={isPreview}
                sx={{ mr: 2 }}
              >
                {r.reset}
              </Button>
            </Box>
          </Box>
          }
          {isPreview && <ReservationFormReview resources={props} formState={formState} paymentType={paymentType} goBack={() => setIsPreview(false)} isPreview={isPreview} />}
        </LocalizationProvider>
      </div>
      {/* <div>
  <div
    className="cf-turnstile"
    data-sitekey="0x4AAAAAAA1Lt9CTFwyDkHnC"
    data-size="flexible"
  ></div>
</div> */}
    </>
  );
}

export default ReservationForm;
