import React, { useState } from 'react';
import { createInvoice } from "./BtcpayApi";
import { generateSepaQRCode } from "./SepaApi";
import { IFormState } from './IFormState';
import { Box, Button, TextField } from '@mui/material';
import { bic, iban, tidyPrice } from './PaymentInfo';

export interface IReservationFormReviewProps {
  resources: LanguageDetail;
  formState: IFormState;
  paymentType: string;
  isPreview: boolean;
  goBack(): void;
}

function ReservationFormReview(props: Readonly<IReservationFormReviewProps>) {
  const [sepaQr, setSepaQr] = useState<string | null>(null);
  const r = props.resources;
  const formState = props.formState;
  
  const handleSubmit = async () => {
    if(props.paymentType === 'btc') {
      const response = await createInvoice(props.formState);
      window.location.href = response.checkoutLink;
    }
    else if(props.paymentType === 'sepa') {
      const sepaQrResult = await generateSepaQRCode(props.formState);
      setSepaQr(sepaQrResult);
    }
  };

  return (
    <>
      <p>{r.previewOrder}</p>
      <p style={{ display: props.paymentType === 'sepa' && props.isPreview ? 'block' : 'none'}}>
        {r.sepa}<br />
        BIC: {bic}
        IBAN: {iban}
        Price (incl. {tidyPrice} EUR cleanup): {formState.amount}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={props.isPreview}
          sx={{ mr: 2 }}
        >
          {r.previewOrder}
        </Button>
      </p>      
      <Box sx={{ p: 3 }}>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "1fr",
            sm: "1fr 1fr",
          }}
          gap={2}
        >
          <TextField
            label={r.mail}
            fullWidth
            value={formState.email}
          />
          <TextField
            label={r.phone}
            fullWidth
            value={formState.phone}
          />
          <TextField
            label={r.addressing}
            fullWidth
            value={formState.title}
          />
          <TextField
            label={r.firstName}
            fullWidth
            value={formState.firstName}
          />
          <TextField
            label={r.lastName}
            fullWidth
            value={formState.lastName}
          />
          <TextField
            label={r.address}
            fullWidth
            value={formState.address}
          />
          <TextField
            label={r.city}
            fullWidth
            value={formState.city}
          />
          <TextField
            label={r.country} 
            fullWidth
            value={formState.country}
          />
          <TextField
            label={r.dateFrom}
            fullWidth
            value={formState.dateFrom?.format('LL')}
          />
          <TextField
            label={r.dateTo}
            fullWidth
            value={formState.dateTo?.format('LL')}
          />
          <TextField
            label={r.numberOfGuests}
            fullWidth
            value={formState.numberOfGuests}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField
              label={r.note}
              fullWidth
              value={formState.note}
              multiline
              sx={{ mb: 2 }}
            />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mr: 2 }}
          >
            {r.submit}
          </Button>
          <Button
            variant="outlined"
            onClick={() => props.goBack()}
            sx={{ mr: 2 }}
          >
            {r.backToOrder}
          </Button>
        </Box>
        {sepaQr && <img width={300} height={300} src={sepaQr} alt="SEPA QR Code" />}
      </Box>
    </>
  );
}

export default ReservationFormReview;
