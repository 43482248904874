import React from "react";
import { Grid2 as Grid, Box, Container, Typography } from "@mui/material";
import MetaTags from "./components/MetaTags";
import { mainSeasonPrice, sideSeasonPrice, tidyPrice } from "./components/Reservation/PaymentInfo";

function Pricing(props: Readonly<LanguageDetail>) {
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.pricing}
        url={window.location.href} />
      <h1>{props.pricing}</h1>
      <Container>
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography>{props.pricePerNight} - {props.mainSeason}: {mainSeasonPrice} EUR</Typography>
              <Typography>{props.pricePerNight} - {props.sideSeason}: {sideSeasonPrice} EUR</Typography>
              <Typography>{props.cleaning}: {tidyPrice} EUR</Typography>
              <Typography>{props.discountInfo}</Typography>
              <br />
              <Typography>{props.priceInfo}</Typography>
              <br />
              <Typography>
                <a href="https://www.booking.com/Share-B4f11z">{props.bookingReservation}</a>
              </Typography>
              <Typography>
                <a href="https://airbnb.com/h/lovebania">{props.airbnbReservation}</a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Pricing;
