import React, { useState, useEffect } from 'react';
import './Gallery.scss'; // Create this file for styling

interface Image {
  src: string;
  alt: string;
}

function Gallery(props: Readonly<LanguageDetail>) {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [touchStartX, setTouchStartX] = useState<number>(0);
  const [touchEndX, setTouchEndX] = useState<number>(0);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const images: Image[] = [
    { src: require('./gallery/IMG_20250202_063043.webp'), alt: 'Image 1' },
    { src: require('./gallery/IMG_20250202_063058.webp'), alt: 'Image 2' },
    { src: require('./gallery/IMG_20250202_063028.webp'), alt: 'Image 3' },
    { src: require('./gallery/IMG_20250202_062943.webp'), alt: 'Image 4' },
    { src: require('./gallery/IMG_20250202_062958.webp'), alt: 'Image 6' },
    { src: require('./gallery/IMG_20241026_100722.webp'), alt: 'Image 7' },
    { src: require('./gallery/IMG_20241128_150355.webp'), alt: 'Image 8' },
    { src: require('./gallery/IMG_20241026_092259.webp'), alt: 'Image 9' },
    { src: require('./gallery/IMG_20250131_173541.webp'), alt: 'Image 10' },
    { src: require('./gallery/IMG_20241023_171717.webp'), alt: 'Image 11' },
    { src: require('./gallery/IMG_20241025_172814.webp'), alt: 'Image 12' },
    { src: require('./gallery/original.webp'), alt: 'Lovebania' },
  ];

  const handleImageClick = (src: string) => {
    setSelectedImage(src);
    setIsOverlayOpen(true);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setIsOverlayOpen(false);
  };

  const imageIndex = images.findIndex(image => image.src === selectedImage);

  const goToNextImage = () => {
    if (imageIndex !== -1) {
      const nextIndex = (imageIndex + 1) % images.length;
      setSelectedImage(images[nextIndex].src);
    }
  };

  const goToPrevImage = () => {
    if (imageIndex !== -1) {
      const prevIndex = (imageIndex - 1 + images.length) % images.length;
      setSelectedImage(images[prevIndex].src);
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!touchStartX) return;
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!touchStartX) return;
    const diffX = touchEndX - touchStartX;

    if (Math.abs(diffX) > 50) {
      if (diffX > 0) {
        goToPrevImage();
      } else {
        goToNextImage();
      }
    }

    setTouchStartX(0);
    setTouchEndX(0);
  };

  useEffect(() => {
    if (isOverlayOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto'; // Reset on unmount
    };
  }, [isOverlayOpen]);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      } else if (event.key === 'ArrowRight') {
        goToNextImage();
      } else if (event.key === 'ArrowLeft') {
        goToPrevImage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose, goToNextImage, goToPrevImage, images, selectedImage]);

  return (
    <div className="gallery-container">
      <h1>{props.gallery}</h1>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className="gallery-image"
            onClick={() => handleImageClick(image.src)}
          />
        ))}
      </div>
      {selectedImage && (
        <div className="full-image-overlay"
             onClick={handleClose}
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onTouchEnd={handleTouchEnd}
        >
          <img src={selectedImage} alt="Full size" className="full-image" />
        </div>
      )}
    </div>
  );
}

export default Gallery;
