import React, { useEffect, useState } from 'react';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from 'dayjs';
import { generateDateRanges, isDateDisabledForDateFrom, isDateDisabledForDateTo } from '../utils/DateUtils';
import { fetchCalendarEvents, getEventTimes } from '../utils/CalendarUtils';
import { IFormState } from './Reservation/IFormState';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setSession } from '../utils/SessionUtils';

export interface IDateRangeSelectorProps {
  resource: LanguageDetail;
  onDateChanged: (field: keyof IFormState, val: Dayjs | null) => void;
  isHome?: boolean;
}

const calendarUri =
  "https://www.googleapis.com/calendar/v3/calendars/8a6b6dab0f218ab39ff178fec62cb7615a19e97e94afb01d9e85bff2c9966a06@group.calendar.google.com/events?key=AIzaSyAhuPC49ffZTcZ3n0fD_xVhGUbuQso0QjE";

function DateRangeSelector(props: Readonly<IDateRangeSelectorProps>) {
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(null);
  const [dateTo, setDateTo] = useState<Dayjs | null>(null);
  const [bookedDates, setBookedDates] = useState<Dayjs[]>([]);
  const r = props.resource;

  useEffect(() => {
    fetchCalendarEvents(calendarUri)
      .then((calendarData) => getEventTimes(calendarData))
      .then((events) => {
        setBookedDates(generateDateRanges(events));
      })
      .catch((error) => {
        console.error("Error fetching or processing calendar events:", error);
      });
  }, []);

  const checkAvailability = () => {
    setSession('dateFrom', dateFrom);
    setSession('dateTo', dateTo);
    navigate('reservation');
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={r.dateFrom}
        value={dateFrom}
        onChange={(date) => { 
          props.onDateChanged("dateFrom", date); 
          setDateFrom(date);
        }}
        shouldDisableDate={(d) =>
          isDateDisabledForDateFrom(d, dateTo, bookedDates)
        }
      />
      <DatePicker
        label={r.dateTo}
        value={dateTo}
        onChange={(date) => {
          props.onDateChanged("dateTo", date);
          setDateTo(date);
        }}
        shouldDisableDate={(d) =>
          isDateDisabledForDateTo(d, dateFrom, bookedDates)
        }
      />
      { props.isHome && <Button
        variant="outlined"
        onClick={checkAvailability}
        sx={{ mr: 2 }}
      >
        overit dostupnost
      </Button>}

    </LocalizationProvider>
  );
}

export default DateRangeSelector;