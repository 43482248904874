import dayjs, { Dayjs } from 'dayjs';

export async function fetchCalendarEvents(url: string): Promise<any> {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`Failed to fetch calendar events: ${response.statusText}`);
  }

  return response.json();
}

export async function getEventTimes(response: any): Promise<{ start: Dayjs; end: Dayjs }[]> {
  return (response.items || []).map((event: any) => {
    const start = event.start?.dateTime || event.start?.date;
    const end = event.end?.dateTime || event.end?.date;

    if (!start || !end) {
      throw new Error('Event is missing start or end time');
    }

    return {
      start: dayjs(start),
      end: dayjs(end),
    };
  });
}
