import React from "react";
import { Grid2 as Grid, Box, Container } from "@mui/material";
import albania from './img/albania.webp';
import flat1 from './img/flat1.webp';
import flat2 from './img/flat2.webp';
import flat3 from './img/flat3.webp';
import flat4 from './img/flat4.webp';
import flat5 from './img/flat5.webp';
import home1 from './img/home1.webp';
import home2 from './img/home2.webp';
import home3 from './img/home3.webp';
import home4 from './img/home4.webp';
import home5 from './img/home5.webp';
import MetaTags from "./components/MetaTags";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';
import DateRangeSelector from "./components/DateRangeSelector";
import { isDev } from "./utils/DevUtils";

function Home(props: Readonly<LanguageDetail>) {
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.headline}
        url={window.location.href} />
      <h1>{props.headline}</h1>
      <Container>
        { isDev() && <DateRangeSelector resource={props} isHome={true} onDateChanged={(_) => {}} />}
        <Swiper 
          className="home-carousel"
          loop={true}
          pagination={true} 
          effect={'fade'}
          modules={[Autoplay, EffectFade, Pagination]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          <SwiperSlide><img alt="" src={albania} /></SwiperSlide>
          <SwiperSlide><img alt="" src={flat1} /></SwiperSlide>
          <SwiperSlide><img alt="" src={flat3} /></SwiperSlide>
          <SwiperSlide><img alt="" src={flat4} /></SwiperSlide>
          <SwiperSlide><img alt="" src={home1} /></SwiperSlide>
          <SwiperSlide><img alt="" src={home2} /></SwiperSlide>
          <SwiperSlide><img alt="" src={home3} /></SwiperSlide>
          <SwiperSlide><img alt="" src={home4} /></SwiperSlide>
          <SwiperSlide><img alt="" src={home5} /></SwiperSlide>
        </Swiper>
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              {props.homeGolem}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Home;
