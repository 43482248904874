import React from "react";
import { Grid2 as Grid, Box } from "@mui/material";
import albania from './img/albania.webp';
import beach from './img/beach.webp';
import MetaTags from "./components/MetaTags";

function Home(props: LanguageDetail) {
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.headline}
        url={window.location.href}
        image={albania} />
      <h1>{props.headline}</h1>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            {props.homeAlbania}
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <img src={albania} alt="Logo" style={{ width: '100%' }} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <img src={beach} alt="Logo" style={{ width: '100%' }} />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            {props.homeGolem}
          </Grid>
          <Grid size={{ xs: 12 }}>
            {props.homeGolemAd}
          </Grid>
          {/* <Grid size={{ xs: 12, sm: 6 }}>
            <img src="/images/beach-photo.jpg" alt="Logo" style={{ width: '100%' }} />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
}

export default Home;
