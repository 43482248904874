import { ISession } from "./ISession";

const sessionName = 'Session';

export function setSession(key: keyof ISession, val: any) {
    const currentSession = JSON.parse(sessionStorage.getItem(sessionName) ?? '{}') as ISession;
    currentSession[key] = val;
    sessionStorage.setItem(sessionName, JSON.stringify(currentSession));
}

export function getSession(key: keyof ISession) {
    const currentSession = JSON.parse(sessionStorage.getItem(sessionName) ?? '{}') as ISession;
    return currentSession[key] || null;
}
