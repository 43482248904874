import React, { useState } from 'react';
import Parser from 'html-react-parser';
import MetaTags from './components/MetaTags';
import GoogleMapComponent, { lovebaniaPosition } from './components/GoogleMapComponent';
import { Alert, Button, Grid2 as Grid, Snackbar, TextField, Typography } from '@mui/material';
import { bic, iban } from './components/Reservation/PaymentInfo';

function Contact(props: Readonly<LanguageDetail>) {
  const user = 'stay';
  const domain = 'lovebania.com';
  const mail = `${user}@${domain}`;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    page: window.location.href
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch("https://hook.eu2.make.com/8ko4v2mb74y67sspy3ef9dwt1vm474wy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);
        setFormData({ name: "", email: "", phone: "", message: "", page: window.location.href });
      } else {
        throw new Error("Failed to submit");
      }
    } catch (error) {
      setError(true);
    }
  };
  
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.contact}
        url={window.location.href} />
      <h1>{ Parser(props.contact) }</h1>
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="h5">{props.title}</Typography>
          <Typography><b>{props.address}: </b>Rruga Shkëmbi i Kavajës, 2021 Durrës, Rrashbull Durrës Shqipëria</Typography>
          <Typography><b>GPS:</b> Lat: {lovebaniaPosition.lat}, Lng: {lovebaniaPosition.lng}</Typography>
          <Typography><b>{props.mail}: </b>
            <a href={`mailto:${mail}`}>
              {user}@{domain}
            </a>
          </Typography>
          <Typography><b>IBAN:</b> {iban}</Typography>
          <Typography><b>BIC:</b> {bic}</Typography>
          <Typography>{props.priceInfo}</Typography>
          <Typography>
            <a href="https://www.booking.com/Share-B4f11z">{props.bookingReservation}</a>
           </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="h5">{props.writeUs}</Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid size={12}>
                <TextField
                  fullWidth
                  required
                  label={`${props.firstName} & ${props.lastName}`}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  required
                  label={props.mail}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  label={props.phone}
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  required
                  multiline
                  rows={4}
                  label={props.message}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Grid>
              <Grid>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  {props.submit}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert severity="success" onClose={() => setSuccess(false)}>
          {props.messageSentOk}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
        <Alert severity="error" onClose={() => setError(false)}>
          {props.messageSentError}
        </Alert>
      </Snackbar>
      <GoogleMapComponent />
    </>
  );
}

export default Contact;
