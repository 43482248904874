// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
  padding: 20px;
  text-align: center;
}
.gallery-container.no-scroll {
  overflow: hidden;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.gallery-image {
  width: 100%;
  height: 200px; /* Or any desired fixed height */
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}
.gallery-image:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.full-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.full-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/Gallery.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,aAAA;EACA,2DAAA;EACA,SAAA;EACA,aAAA;AAAF;;AAGA;EACE,WAAA;EACA,aAAA,EAAA,gCAAA;EACA,iBAAA;EACA,kBAAA;EACA,wCAAA;EACA,sCAAA;AAAF;AAEE;EACE,sBAAA;EACA,eAAA;AAAJ;;AAIA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,yCAAA;AADF","sourcesContent":[".gallery-container {\n  padding: 20px;\n  text-align: center;\n\n  &.no-scroll {\n    overflow: hidden;\n  }\n}\n\n.gallery-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n  gap: 20px;\n  padding: 20px;\n}\n\n.gallery-image {\n  width: 100%;\n  height: 200px; /* Or any desired fixed height */\n  object-fit: cover;\n  border-radius: 8px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  transition: transform 0.2s ease-in-out;\n\n  &:hover {\n    transform: scale(1.05);\n    cursor: pointer;\n  }\n}\n\n.full-image-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.9);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.full-image {\n  max-width: 90%;\n  max-height: 90%;\n  border-radius: 8px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
